.overlay {
  @apply pointer-events-auto fixed right-0 top-0 z-50 flex h-screen w-screen origin-bottom animate-modal-overlay place-items-center items-center justify-center bg-[rgba(0,0,0,0.4)] bp1:transform-none bp1:animate-none bp1:p-4 bp1:opacity-100;

  .container {
    @apply z-50 absolute top-0 bottom-0 w-full overflow-auto rounded-xl flex justify-center items-center;
    .card {
      @apply w-full max-w-[600px] py-11 px-5  bg-white rounded-xl flex flex-col p-3;

      .title {
        @apply font-semibold text-[16px] mb-6;
      }
      .legend {
        @apply font-normal text-[15px];
      }
    }
    .bottom-buttons {
      @apply flex flex-row w-full justify-end gap-2 pt-2;
    }

    .remove-button {
      @apply p-2 px-5 justify-center items-center flex border border-red-600 bg-red-600 text-white text-sm rounded;

      &.disabled {
        @apply bg-gray-300 text-gray-500 border-gray-300;
      }
    }

    .confirm-button {
      @apply p-2 px-5 justify-center items-center flex border border-blue-secondary bg-blue-secondary text-white text-sm rounded;

      &.disabled {
        @apply bg-gray-300 text-gray-500 border-gray-300;
      }
    }
  }
}
