.course-item {
  @apply cursor-pointer;
  &.upcoming {
    @apply cursor-not-allowed;
  }

  &.short {
    @apply min-h-[40px];
    .item-title {
      color: #1b2026;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @apply truncate;
    }

    .progress-icon-container {
      @apply w-[25px] min-w-[25px];
    }

    .type-icon-container {
      @apply w-[20px] min-w-[20px];
    }
    .button-link-container {
      p {
        @apply hidden;
      }
    }
  }
  .progress-icon-container {
    @apply relative flex min-w-9 w-9  min-h-9 h-9 items-center justify-center;
  }

  .type-icon-container {
    @apply flex h-full min-w-[20px] items-center justify-center;
  }

  .item-content {
    @apply flex w-full flex-col items-start justify-center space-y-1 overflow-hidden;

    .title-container {
      @apply flex w-full overflow-hidden;

      .item-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @apply h-full w-full truncate sm:truncate bp1:text-sm;
      }
    }
  }
}
