.extra-rules {
    @apply flex flex-row justify-between;

    .content {
        @apply flex flex-row gap-2;

        .selector {
            @apply h-[40px] items-center justify-between rounded-md border border-black/10;
        }

        .aux-text {
            @apply text-[14px] font-medium opacity-60 my-auto;
        }
    }
}