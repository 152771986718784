.comment-view {
  @apply flex w-full flex-row items-start justify-center gap-3;

  [contenteditable]:focus {
    outline: 0px solid transparent;
  }

  .send-button-container {
    @apply flex items-center justify-end;
    .send-button {
      @apply flex h-[29px] cursor-pointer items-center justify-center rounded-[8px] bg-blue-secondary px-4 text-white;
      font-size: 10px;
    }
  }
  .toolbar-button-container {
    &.active {
      @apply !bg-gray-200;
    }
  }

  .toolbar-button {
    @apply flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-[5px] text-center text-gray-600 hover:bg-gray-300;
    &.active {
      @apply !bg-gray-200;
    }
  }
}
