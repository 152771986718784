.tabs_list {
  @apply flex px-4 gap-3;

  .tab_trigger {
    @apply flex justify-center;

    .tab_trigger_content {
      @apply flex cursor-pointer items-center;

      p {
        @apply text-[12px] font-medium;
      }
    }
  }
}


.popoverContent {
  @apply z-50 outline-none;

  border-radius: 4px;
  width: 242px;
  /* margin-top: -15px; */
  padding: 0;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  .Option {
    @apply flex py-2 px-4;

    &:hover {
      background-color: #C3C3C3FF;
    }
  }
}
.popoverContent:focus {

}
.popoverContent[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.popoverContent[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.popoverContent[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.popoverContent[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.PopoverArrow {
  fill: white;
  left: 0px !important;
}

.IconButton {
  font-family: inherit;
  height: 35px;
  width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.IconButtonTransparent {
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.IconHamburgerButton {
  font-family: inherit;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
}

.Label {
  font-size: 13px;
  width: 75px;
}

.Input {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 1;
  height: 25px;
}
.Input:focus {
}

.Text {
  margin: 0;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}