.in-app-notification {
  @apply grid w-full select-none grid-rows-1 divide-y divide-admin-grey-300 border-b bg-white;

  .section {
    @apply flex flex-col h-[50px];
  }

  .content {
    @apply p-4 flex flex-col gap-2;
  }

  .new {
    @apply bg-[#FAFBFF];
  }

  .title {
    @apply text-[15px] text-black font-semibold;
  }

  .body {
    a,
    span,
    p,
    div {
      @apply text-[13px];
    }
    img {
      @apply mx-auto;
    }

    .video-custom {
      @apply relative w-full min-h-[300px] h-[300px] gap-10;

      iframe {
        @apply absolute border-0 top-0 w-full h-full;
      }
    }
  }
}

.in-app-notification:last-child {
  @apply border-0;
}
