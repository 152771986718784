@keyframes open {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes close {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

footer {
  .card-footer-collapsible {
    @media (max-width: 640px) {
      position: absolute;
      bottom: 100%;
      background: #fafafc;
      width: 100%;
      left: 0;
      padding: 16px;
      border-top: 1px solid #dfdee4;
      box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      &[data-state='open'] {
        animation: open 100ms cubic-bezier(0.77, 0, 0.175, 1) forwards;
      }

      &[data-state='closed'] {
        animation: close 100ms cubic-bezier(0.77, 0, 0.175, 1) forwards;
      }
    }
    @media screen and (min-width: 640px) {
      &[data-state='open'] {
        animation: open 100ms ease-out forwards;
      }

      &[data-state='closed'] {
        animation: close 100ms ease-out forwards;
      }
    }
  }
}
